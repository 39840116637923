import React, { useEffect, useState } from 'react';
import request from '../services/request';
import loadable from '@loadable/component'
import  'bootstrap/dist/css/bootstrap.min.css';
// const Footer = loadable(() => import('../components/Footer'))
// const Nav = loadable(() => import ('../components/Nav'))
import Footer  from "../components/Footer";
import Nav from "../components/Nav";
const SupportForm = loadable(() => import ('../components/Forms/SupportForm'))

// const TopNav = loadable(() => import ('../components/TopNav'))

function Support() {
  const [file, setFile] = useState(null)
  const [name, setName] = useState("")
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [dezyneVersion, setDezyneVersion] = useState("")
  const [description, setDescription] = useState("");
  const handleSendMessage = async(e) => {
    /* if(!name || !email || !subject || !dezyneVersion) {
      e.preventDefault()
      alert("Please enter all the required fields")
    } else { */
        console.log(file, "file")
        const formData = new FormData();
        formData.append("SupportFile", file);
        formData.append("name", name);
        formData.append("email", email);
        formData.append("SupportSubject", subject);
        formData.append("SupportVersion", dezyneVersion);
        formData.append("description", description)
        const response = await request.post('/support', formData);
        console.log(response, "support data")
        if (!response.data) {
            e.preventDefault();
        }
    //}
  }
  return (
    <>
      {/* <TopNav/> */}
      <Nav/>
      <section className="bg-light-gray padding-35px-tb page-title-small top-space-verum">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-xl-8 col-md-6 d-flex flex-column justify-content-center">
                        <h1 className="alt-font text-extra-dark-gray font-weight-600 no-margin-bottom">Support</h1>
                    </div>
                </div>
            </div>
        </section>
        <section className="padding-70px-tb">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 col-lg-8 md-margin-50px-bottom sm-margin-30px-bottom">
                        <h5 className="title-section-alt alt-font">What can we help you with?</h5>
                        <p>Whether itís a change request, bug report or just some help and advice, our development team offer you the help to bring you further. You will receive feedback as soon as possible.</p>
                        <p>Fields marked with an * are required</p>
                        <SupportForm/>
                    </div>
                    <div className="col-12 col-lg-4">
                        <h5 className="title-section-alt alt-font">Support documentation</h5>
                        <ul>
                            <li><a className='anchor' href="http://download.verum.com/documentation.html" target="_blank">Dezyne manual</a></li>
                            <li><a className='anchor' href="http://download.verum.com/download.html" target="_blank">Download Dezyne manual</a></li>
                            <li><a className='anchor' href="http://download.verum.com/documentation.html" target="_blank">Tutorials</a></li>
                            <li><a className='anchor' href="/asd">ASD</a></li>

                        </ul>                        
                    </div>
                </div>                               
            </div>
        </section>
      <Footer/>
    </>
  );
}

export default Support;
